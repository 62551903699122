"use client";

import { useEffect, useState } from "react";

import "./Quiz.css";
import { IQuizQuestion, IQuizUiProps } from "../types";
import {
  createHandleContactSubmit,
  handleAnswerChange,
  handleNext,
  handlePrev,
  normalizeAnswers,
} from "../utils/utils";
import RadioQuestion from "./RadioQuestion/RadioQuestion";
import CheckboxQuestion from "./CheckboxQuestion/CheckboxQuestion";
import QuizForm from "./QuizForm";
import QuizProgress from "./QuizProgress/QuizProgress";
import DownloadLink from "./DownloadFile/DownloadFile";
import { metricsId } from "@/shared/constants/constants";

const QuizUi: React.FC<IQuizUiProps> = ({ locale, quizData, onComplete }) => {
  const { quiz, quizQuestions } = quizData;
  const [answers, setAnswers] = useState<{ [key: number]: string | string[] }>(
    {}
  );
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    name: "",
    contact: "",
    comment: "",
  });

  useEffect(() => {
    const savedQuizStatus = localStorage.getItem("quizCompleted");
    if (savedQuizStatus === "true") {
      setIsFormSubmitted(true);
    }
  }, []);
  const metricsKeys = locale === "ru" ? metricsId.ru : metricsId.com;

  const handleStartQuiz = () => {
    window.ym(parseInt(metricsKeys.yaMetric), "reachGoal", "quizStart"); // Счетчик "quizStart"
  };

  const totalSteps = quizQuestions.length + 1; // Количество шагов включая форму
  const currentStep = currentQuestionIndex + (isQuizCompleted ? 1 : 0);

  const currentQuestion: IQuizQuestion = quizQuestions[currentQuestionIndex];

  const renderQuestionComponent = (question: IQuizQuestion) => {
    const answer =
      answers[question.id] || (question.type === "checkbox" ? [] : "");

    switch (question.type) {
      case "radio":
        return (
          <RadioQuestion
            question={question}
            selectedAnswer={answer as string}
            onAnswerChange={(value: string | string[]) =>
              handleAnswerChange(question.id, value, setAnswers)
            }
          />
        );
      case "checkbox":
        return (
          <CheckboxQuestion
            question={question}
            selectedAnswers={answer as string[]}
            onAnswerChange={(value: string[]) =>
              handleAnswerChange(question.id, value, setAnswers)
            }
          />
        );
      default:
        return null;
    }
  };

  const handleCompleteQuiz = () => {
    setIsQuizCompleted(true);
    onComplete(answers, setAnswers, () => setCurrentQuestionIndex(0));
  };

  const handleContactSubmit = () => {
    createHandleContactSubmit(normalizeAnswers(answers, quizData))(contactInfo);
    setIsFormSubmitted(true);
    localStorage.setItem("quizCompleted", "true");
    window.ym(parseInt(metricsKeys.yaMetric), "reachGoal", "quizSend");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isQuizCompleted) {
      setIsFormSubmitted(true);
      handleContactSubmit();
    } else {
      handleCompleteQuiz();
    }
  };

  return (
    <form className="quiz" onSubmit={handleSubmit}>
      {isFormSubmitted ? (
        <div className="quiz__completion">
          <h2 className="quiz__title">{quiz.completionTitle}</h2>
          <p className="quiz__subtitle">
            {quiz.completionMessage}{" "}
            <DownloadLink label={" " + quiz.getPrizeText} />
          </p>
        </div>
      ) : (
        <>
          <h2 className="quiz__title">{quiz.title}</h2>
          <p className="quiz__subtitle">
            {isQuizCompleted
              ? quiz.quizContactsPlaceholder.title
              : quiz.subtitle}
          </p>
          <QuizProgress currentStep={currentStep} totalSteps={totalSteps} />

          <div className="quiz__question">
            <h3 className="quiz__question-title">
              {isQuizCompleted
                ? quiz.quizContactsPlaceholder.description
                : currentQuestion.question}
            </h3>
            <div className="quiz__options">
              {!isQuizCompleted ? (
                renderQuestionComponent(currentQuestion)
              ) : (
                <QuizForm
                  contactPlaceholder={quiz.quizContactsPlaceholder}
                  contactInfo={contactInfo}
                  setContactInfo={setContactInfo}
                />
              )}
            </div>
          </div>

          <div className="quiz__controls">
            <button
              type="button"
              className="quiz__button quiz__button_prev"
              onClick={() => {
                if (isQuizCompleted) {
                  setIsQuizCompleted(false); // Сброс состояния завершения
                  setCurrentQuestionIndex(quizQuestions.length - 1); // Возврат к последнему вопросу
                } else {
                  handlePrev(currentQuestionIndex, setCurrentQuestionIndex);
                }
              }}
              disabled={currentQuestionIndex === 0 && !isQuizCompleted}
            >
              {quiz.prevBttnText}
            </button>
            <button
              className="quiz__button quiz__button_next"
              type="button"
              onClick={() => {
                const form = document.querySelector(".quiz");
                if (form instanceof HTMLFormElement && !form.checkValidity()) {
                  form.reportValidity();
                  return;
                }

                if (currentQuestionIndex === 0 && !isQuizCompleted) {
                  handleStartQuiz();
                }

                if (currentQuestionIndex < quizQuestions.length - 1) {
                  handleNext(
                    currentQuestionIndex,
                    setCurrentQuestionIndex,
                    quizQuestions.length - 1
                  );
                } else if (!isQuizCompleted) {
                  handleCompleteQuiz();
                } else {
                  setCurrentQuestionIndex(totalSteps - 1);
                  handleContactSubmit();
                }
              }}
            >
              {isQuizCompleted ? quiz.submitBttnText : quiz.nextBttnText}
            </button>
          </div>
        </>
      )}
    </form>
  );
};

export default QuizUi;
