import { IQuizQuestion } from "../../types";
import './RadioQuestion.css';


interface RadioQuestionProps {
  question: IQuizQuestion;
  selectedAnswer: string;
  onAnswerChange: (value: string) => void;
}

const RadioQuestion: React.FC<RadioQuestionProps> = ({
  question,
  selectedAnswer,
  onAnswerChange,
}) => (
  <div className="quiz__radio-group">
    {question.quizOptions.map((option) => (
      <label key={option.id} className="quiz__radio-option">
        <input
          type="radio"
          name={`question-${question.id}`}
          value={option.value}
          checked={selectedAnswer === option.value}
          onChange={(e) => onAnswerChange(e.target.value)}
          className="quiz__radio-input"
          required={question.required || false}

        />
        <span className="quiz__radio-label">{option.label}</span>
      </label>
    ))}
  </div>
);

export default RadioQuestion;
