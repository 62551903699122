"use client";

import { useRef, useEffect } from "react";
import { CollapsingData } from "./CollapsingData/CollapsingData";
import whiteArrow from "@/shared/images/arrow_down.webp";
import blackArrow from "@/shared/images/arrow_down-black.webp";
import { IFeatureBlock } from "./interfaces";
import Image from "next/image";
import "./CollapsingField.css";

export const CollapsingField: React.FC<IFeatureBlock> = ({
  title,
  text,
  iconStyle = "black",
  type,
  isOpen,
  onToggle,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const icon = iconStyle === "white" ? whiteArrow : blackArrow;

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isOpen
        ? `${contentRef.current.scrollHeight}px`
        : "0px";
    }
  }, [isOpen]);

  return (
    <>
      <div className={"collapsing__container"}>
        <div className={"collapsing__bar"} onClick={onToggle}>
          <h4 className={"collapsing__title"}>{title}</h4>
          <Image
            className={`collapsing__img ${isOpen ? "open" : ""}`}
            alt="arrow down"
            src={icon}
            width={14}
            height={14}
          />
        </div>
        <div
          ref={contentRef}
          className={`collapsing__content ${isOpen ? "open" : ""}`}
        >
          <CollapsingData text={text} type={type} />
        </div>
      </div>
      <hr className={"collapsing__bottomLine"} />
    </>
  );
};
