'use client'; 

import { FullpageScroll } from "@/features/FullpageScroll";
import { usePathname } from "next/navigation";

const FullpageScrollWrapper = () => {
  const path = usePathname();
 
  return (
    <>
      {path === "/scroll-test" && (
        <FullpageScroll
          containerSelector=".mainTest-wrapper"
          slideSelector=".scroll-snap"
        />
      )}
    </>
  );
};

export default FullpageScrollWrapper;
