"use client";

import { useEffect, useState, useRef } from "react";
import { FullpageScriptOptions } from "./types";
import { findFullpageElements } from "./fullpageDom";
import {
  handleWheelEvent,
  handleKeyDownEvent,
  handleTouchMoveEvent,
  handleTouchStartEvent,
  FullpageEventHandlers,
} from "./fullpageEvents";
import React from "react";

/**
 * Клиентский компонент, который "перехватывает" скролл
 * и переключает слайды, найденные через querySelector.
 */
export function FullpageScroll(props: FullpageScriptOptions) {
  const { containerSelector, slideSelector, options = {} } = props;

  const {
    initialSlide = 0,
    direction = "vertical",
    loop = false,
    keyboard = true,
    touch = true,
    touchThreshold = 50,
    scrollDebounceTime = 700,
    transitionDuration = 700,
    disableScrolling = false,
    allowContentScroll = false,
    allowScrollBeyondLast = false,
    onBeforeSlideChange,
    onAfterSlideChange,
    onReachEdge,
    debug = true,
  } = options;

  // Локальный стейт: currentSlide
  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const isScrollingRef = useRef(false);
  const touchStartRef = useRef<number | null>(null);

  // Храним найденные DOM-элементы (контейнер, массив слайдов).
  // Можно хранить в useRef, чтобы при ререндере не терялись
  // (но это не "ref" в смысле, что мы не привязываемся к JSX).
  const containerRef = useRef<HTMLElement | null>(null);
  const slidesRef = useRef<HTMLElement[]>([]);

  // ================================
  // 1) Основная логика переключения
  // ================================
  function nextSlide() {
    goToSlide(currentSlide + 1);
  }
  function prevSlide() {
    goToSlide(currentSlide - 1);
  }

  function goToSlide(index: number) {
    if (debug) console.log(`goToSlide(${index}) from current=${currentSlide}`);
    if (disableScrolling) return;
    if (isScrollingRef.current) return;

    const slides = slidesRef.current;
    const maxIndex = slides.length - 1;

    let targetIndex = index;

    // Логика loop
    if (loop && slides.length > 0) {
      if (targetIndex < 0) {
        targetIndex = maxIndex;
      } else if (targetIndex > maxIndex) {
        targetIndex = 0;
      }
    } else {
      // Нет loop
      if (targetIndex < 0) {
        onReachEdge?.("start");
        if (!allowScrollBeyondLast) {
          targetIndex = 0;
        } else {
          return;
        }
      }
      if (targetIndex > maxIndex) {
        onReachEdge?.("end");
        if (!allowScrollBeyondLast) {
          targetIndex = maxIndex;
        } else {
          return;
        }
      }
    }

    if (onBeforeSlideChange) {
      const canContinue = onBeforeSlideChange(currentSlide, targetIndex);
      if (canContinue === false) return;
    }

    // Прокручиваем
    scrollToSlide(targetIndex);

    // Ставим debounce
    isScrollingRef.current = true;
    setTimeout(() => {
      isScrollingRef.current = false;
    }, scrollDebounceTime);
  }

  function scrollToSlide(index: number) {
    setCurrentSlide(index);

    const slides = slidesRef.current;
    const slideEl = slides[index];
    if (!slideEl) return;

    // Плавная прокрутка к конкретному элементу
    slideEl.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });

    // Поскольку нет нативного колбэка окончания "smooth"‑скролла,
    // используем setTimeout
    setTimeout(() => {
      onAfterSlideChange?.(currentSlide, index);
    }, transitionDuration);
  }

  // ================================
  // 2) Формируем объект "eventHandlers"
  // ================================
  const eventHandlers: FullpageEventHandlers = {
    disableScrolling,
    direction,
    keyboard,
    touch,
    touchThreshold,
    allowContentScroll,

    nextSlide,
    prevSlide,
  };

  // ================================
  // 3) Хендлеры для событий
  // ================================
  function onWheel(e: React.WheelEvent<Element>) {
    handleWheelEvent(e, eventHandlers);
  }
  function onKeyDown(e: React.KeyboardEvent<Element>) {
    handleKeyDownEvent(e, eventHandlers);
  }
  function onTouchStart(e: React.TouchEvent<Element>) {
    handleTouchStartEvent(e, eventHandlers, (val) => {
      touchStartRef.current = val;
    });
  }
  function onTouchMove(e: React.TouchEvent<Element>) {
    handleTouchMoveEvent(e, eventHandlers, touchStartRef.current, () => {
      touchStartRef.current = null;
    });
  }

  // ================================
  // 4) Навешиваем/снимаем слушатели
  // ================================
  useEffect(() => {
    // Ищем контейнер и слайды
    const { container, slides } = findFullpageElements(
      containerSelector,
      slideSelector
    );
    containerRef.current = container ?? null;
    slidesRef.current = slides;

    // Переходим на initialSlide (без анимации)
    if (slides[initialSlide]) {
      slides[initialSlide].scrollIntoView({ behavior: "auto" });
    }

    if (!container) {
      console.warn("[FullpageScript] no container => no listeners attached.");
      return;
    }

    // Навешиваем
    // Можно на window, можно на container - зависит от того, что нужно
    container.addEventListener("wheel", onWheel as unknown as EventListener, {
      passive: false,
    });
    window.addEventListener("keydown", onKeyDown  as unknown as EventListener);
    container.addEventListener("touchstart", onTouchStart  as unknown as EventListener, {
      passive: true,
    });
    container.addEventListener("touchmove", onTouchMove  as unknown as EventListener, {
      passive: false,
    });

    return () => {
      // Снимаем
      container.removeEventListener("wheel", onWheel  as unknown as EventListener);
      window.removeEventListener("keydown", onKeyDown  as unknown as EventListener);
      container.removeEventListener(
        "touchstart",
        onTouchStart as unknown  as EventListener
      );
      container.removeEventListener("touchmove", onTouchMove as unknown  as EventListener);
    };
  }, []); // один раз при маунте

  return null; // Ничего не рендерим
}
