"use client";

import { ProgressBar } from "@/shared/ui/ProgressBar/ProgressBar";
import { IMainCarousel } from "../types";
import Link from "next/link";
import Image from "next/image";
import "./MainCarousel.css";
import { useMainCarousel } from "../model/useMainCarousel";
import { useMemo } from "react";
import { getRandomContent } from "@/shared/lib/getRandomArr";

const MainCarouselClient: React.FC<IMainCarousel> = ({ projectsList }) => {
  const content = projectsList;

  const {
    rootRef,
    itemsRef,
    currentIndex,
    swipeHandlers,
    handleKeyPress,
    handleScrollLeft,
    handleScrollRight,
    handleWheel,
  } = useMainCarousel(content);

  return (
    <section className={"carousel__main wrapper animate scroll-snap"} {...swipeHandlers}>
      <div
        className={"carousel__inner"}
        ref={rootRef}
        onKeyDown={handleKeyPress}
        onWheel={handleWheel}
      >
        {content.map((item, index) => (
          <Link
            className={"carousel__slide"}
            href={`/portfolio/` + item.slug}
            key={index}
            ref={(node) => {
              if (node) {
                itemsRef.current.set(index, node);
              } else {
                itemsRef.current.delete(index);
              }
            }}
          >
            <div className={"carousel____imgWrapper"}>
              <Image
                width={1000}
                height={1000}
                className={"carousel__img"}
                src={item.imgUrl}
                alt="slide img"
                loading="eager"
              />
            </div>
            <div className={"carousel__descriptionContainer"}>
              <div className={"carousel__descriptionLine"} />
              <span className={"carousel__description"}>{item.title}</span>
              <div className={"carousel__descriptionLine_after"} />
            </div>
          </Link>
        ))}
      </div>
      <div className={"carousel__indexContainer"}>
        <div
          className={"carousel__arrow carousel__arrow_left"}
          onClick={handleScrollLeft}
        />
        <span className={"carousel__indexItem"}>
          {currentIndex < 9 ? `${currentIndex + 1}` : currentIndex + 1}
          {/* {currentIndex < 9 ? `0${currentIndex + 1}` : currentIndex + 1} */}
        </span>
        <hr className={"carousel__indexLine"} />
        <span className={"carousel__indexItem"}>
          {currentIndex < 10 ? `${content.length}` : content.length}
        </span>
        <div
          className={"carousel__arrow carousel__arrow_right"}
          onClick={handleScrollRight}
        />
      </div>
      <ProgressBar length={content.length} index={currentIndex} />
    </section>
  );
};

export default MainCarouselClient;
