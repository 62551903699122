"use client";

import "./QuizForm.css";
import { IInputField, QuizFormProps } from "../types";
import { handleContactChange } from "../utils/utils";
import { InputTextField } from "@/widgets/OrderForm/ui/InputTextField/InputTextField";
import { TextareaField } from "@/widgets/OrderForm/ui/TextareaField/TextareaField";


const QuizForm: React.FC<QuizFormProps> = ({
  contactPlaceholder,
  contactInfo,
  setContactInfo,
}) => {
  const nameField: IInputField = {
    label: contactPlaceholder.name,
    autoComplete: "name",
    pattern: /^[\p{L}\s\p{M}\-'"]{0,50}$/u,
    cssName: "name",
    minLength: 1,
    maxLength: 20,
    required:false,
    onChange: (e) => handleContactChange(e, setContactInfo),
    setContactInfo: setContactInfo,
  };

  const contactField: IInputField = {
    label: contactPlaceholder.phoneEmailTg,
    autoComplete: "tel",
    pattern:
      /^(([a-zA-Z0-9_\.+\-]+@[a-zA-Z0-9\-]+\.[a-zA-Z]{2,})|(\+?[\d\- ]{1,3}[\d\s\-]{1,15})|(@[a-zA-Z0-9_]{3,32}))$/,
    cssName: "contact",
    minLength: 3,
    maxLength: 30,
    required:false,
    onChange: (e) => handleContactChange(e, setContactInfo),
    setContactInfo: setContactInfo,
  };

  const commentField: IInputField = {
    label: contactPlaceholder.comment,
    autoComplete: "comment",
    pattern: /^[\p{L}\s\p{M}\-'"]{0,50}$/u,
    cssName: "comment",
    minLength: 2,
    maxLength: 100,
    required:false,
    onChange: (e) => handleContactChange(e, setContactInfo),
    setContactInfo: setContactInfo,
  };

  return (
    <div className="quiz__contact-form">
      <InputTextField fieldData={nameField} />
      <InputTextField fieldData={contactField} />
      <TextareaField fieldData={commentField} />
    </div>
  );
};

export default QuizForm;
