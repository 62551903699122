import AutotextSliderClient from "../AutotextSlider/ui/AutotextSliderClient";
import "./PageTitle.css";
import { IPageTitle } from "./interfaces";

export const PageTitle: React.FC<IPageTitle> = ({
  description,
  title,
  position = "",
  subtitle = "",
  autoslider
}) => {
  return (
    <div className={`pageTitle__content ${position}`}>
      <h2 className={"pageTitle__description"}>{description}</h2>
      <h1 className={"pageTitle__title"}>{title}</h1>
      {(autoslider && !!autoslider.length) && <AutotextSliderClient autoslider={autoslider} />}
      {/* {subtitle && <h3 className={"pageTitle__subtitle"}>{subtitle}</h3>} */}
    </div>
  );
};
