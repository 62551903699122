'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';

type ScrollToElementProps = {
  anchor: string;
  selector: string;
  offset?: number; 
};

export default function ScrollToElement({ anchor, selector, offset = 0 }: ScrollToElementProps) {
  const router = useRouter();

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hash.includes(`#${anchor}`)) {
      const targetElement = document.querySelector(selector);
      if (targetElement) {
        const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;
        const adjustedOffset = window.innerWidth > 600 ? offset / 2 : offset; // Корректируем оффсет для больших разрешений
        const offsetPosition = elementPosition - adjustedOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });

        history.replaceState(null, '', window.location.pathname);
      }
    }
  }, [anchor, selector, offset]);

  return null;
}
