import AboutPage from "@/app/about/page";

const baseUrl = "https://api.blwall.com/api/";
const localePath = "?locale=";

const collectionNames: Record<string, string> = {
  mainPage: "main-pages",
  staticTexts: "static-text",
  header: "header",
  footer: "footer",
  briefPage: "brief",
  faqPage: "faq",
  terms: "terms-and-condition",
  notFound: "not-found",
  orderForm: "order-form",
  contactUs: "contact-us",
  aboutPage: "about",
  projectList: "projects",
  articlesList: "articles",
  portfolioPage: "portfolio-pages",
  contactsPage: "contacts-pages",
  pricesPage: "prices-pages",
  projectPage: "projects",
  topicsPage: "topics-pages",
  articlePage: "articles",
  tags: "tags",
  instagram: "instagram",
};

const urlNames: Record<string, string> = {
  mainPage: "",
  portfolioPage: "portfolio",
  contactsPage: "contacts",
  pricesPage: "prices",
  projectPage: "portfolio",
  articlePage: "topics",
  aboutPage: "about",
  faqPage: "faq",
  briefPage: "brief",
};

const exactPaths: Record<string, any> = {
  mainPage: {
    awardsAndPress: "&populate[awardsAndPress][populate]=%2A",
    awardsTitle: "",
    about: "&populate[about][populate[slide][populate]]=%2A",
    newProjects: "&populate[newProjects][populate]=%2A",
    ourTeam: "&populate[ourTeam][populate]=%2A",
    contactUs: "&populate[contactUs][populate]=%2A",
    orderForm: "&populate[orderForm][populate]=%2A",
    hero: "&populate[hero][populate]=%2A",
    slides: "&populate[slides][populate]=%2A",
    projectItem: "&populate[projectItem][populate]=%2A",
    teamMember: "&populate[teamMember][populate]=%2A",
    instagram: "&populate[instagram][populate]=%2A",
    seoInformation: "&populate[seoInformation][populate]=%2A",
    news: "&populate[news][populate][card][populate]=%2A",
    press: "&populate[press][populate][card][populate]=%2A",
    awards: "&populate[awards][populate][card][populate]=%2A",
    feedback: "&populate[feedback][populate][avatar][populate]=%2A",
    quiz: "&populate[quiz][populate][quizContactsPlaceholder][populate]=%2A",
    orderFormCustom: "&populate[orderFormCustom][populate]=%2A",
    quizQuestions:
      "&populate[quizQuestions][populate][quizOptions]=*&populate[quizQuestions][populate][logicBranch]=*",
    mainPageFull:
      "&populate[about][populate[slide][populate]]=%2A&populate[newProjects][populate]=%2A" +
      "&populate[ourTeam][populate]=%2A&populate[contactUs][populate]=%2A&populate[orderForm]" +
      "[populate]=%2A&populate[hero][populate]=%2A&populate[slides][populate]=%2A&populate[projectItem]" +
      "[populate]=%2A&populate[teamMember][populate]=%2A&populate[instagram][populate]" +
      "&populate[seoInformation][populate]=%2A&populate[news][populate][card][populate]" +
      "=%2A&populate[press][populate][card][populate]=%2A&populate[awards][populate][card][populate]=%2A" +
      "&populate[Feedback]=%2A" +
      "&populate[quiz][populate][quizContactsInfo][populate]=%2A" +
      "&populate[quizQuestions][populate][quizOptions]=*&populate[quizQuestions][populate][logicBranch]=*",
  },
  pricesPage: {
    priceCard: "&populate[priceCard][populate]=%2A",
    priceDetails: "&populate[priceDetails][populate]=%2A",
    workflow: "&populate[workflow][populate]=%2A",
    seoInformation: "&populate[seoInformation][populate]=%2A",
    pageTitle: "&populate[pageTitle][populate]=%2A",
    pricesPageFull:
      "&populate[priceCard][populate]=%2A&populate[pageTitle][populate]=%2A&populate[priceDetails]" +
      "[populate]=%2A&populate[workflow][populate]=%2A&populate[seoInformation][populate]=%2A",
    orderFormCustom: "&populate[orderFormCustom][populate]=%2A",
  },
  projectPage: {
    hero: "&populate[hero][populate]=%2A",
    slider: "&populate[slider][populate]=%2A",
    aboutProject: "&populate[aboutProject][populate]=%2A",
    projectElement: "&populate[projectElement][populate]=%2A",
    imageItem: "&populate[image-item][populate]=%2A",
    gallery: "&populate[gallery][populate]=%2A",
    seoInformation: "&populate[seoInformation][populate]=%2A",
    projectPageFull:
      "&populate[hero][populate]=%2A&populate[slider][populate]=%2A&populate[aboutProject][populate]=%2A" +
      "&populate[projectElement][populate]=%2A&populate[image-item][populate]=%2A&populate[gallery][populate]=%2" +
      "A&populate[seoInformation][populate]=%2A",
  },
  articlePage: {
    hero: "&populate[hero][populate]=%2A",
    slider: "&populate[slider][populate]=%2A",
    aboutProject: "&populate[aboutProject][populate]=%2A",
    projectElement: "&populate[projectElement][populate]=%2A",
    imageItem: "&populate[image-item][populate]=%2A",
    gallery: "&populate[gallery][populate]=%2A",
    seoInformation: "&populate[seoInformation][populate]=%2A",
    articlePageFull:
      "&populate[hero][populate]=%2A&populate[slider][populate]=%2A&populate[aboutProject][populate]=%2A" +
      "&populate[projectElement][populate]=%2A&populate[image-item][populate]=%2A&populate[gallery][populate]=%2" +
      "A&populate[seoInformation][populate]=%2A",
  },
  staticTexts: "&populate=%2A",
  header: "&populate[header][populate]=%2A",
  footer: "&populate[footer][populate]=%2A",
  aboutPage: {
    workflow: "&populate[workflow][populate]=%2A",
    aboutPage: "&populate[about][populate]=%2A",
    seoInformation: "&populate[seoInformation][populate]=%2A",
    orderFormCustom: "&populate[orderFormCustom][populate]=%2A",
  },
  briefPage: {
    pageTitle: "&populate[pageTitle][populate]=%2A",
    quiz: "&populate[quiz][populate][quizContactsPlaceholder][populate]=%2A",
    quizQuestions:
      "&populate[quizQuestions][populate][quizOptions]=*&populate[quizQuestions][populate][logicBranch]=*",
    seoInformation: "&populate[seoInformation][populate]=%2A",
    briefPageFull:
      "&populate[pageTitle][populate]=%2A&populate[quizQuestions][populate][quizOptions]=*&populate[quizQuestions][populate][logicBranch]=*&populate[quiz][populate][quizContactsPlaceholder][populate]=%2A&populate=seoInformation",
  },
  faqPage: {
    pageTitle: "&populate[pageTitle][populate]=%2A",
    seoInformation: "&populate[seoInformation][populate]=%2A",
    faqPageFull:
      "&populate[pageTitle][populate]=%2A&populate[seoInformation][populate]=%2A",
  },
  faqPageFull:
    "&populate[pageTitle][populate]=%2A&populate[seoInformation][populate]=%2A",
  instagram: "&populate=%2A",
  terms: "",
  notFound: "",
  orderForm: "&populate[formAvatars][populate]=%2A",
  contactUs: "&populate=%2A",
  projectList:
    "&populate[portfolioItem][populate]=%2A&populate[seoInformation][populate]=%2A",
  articlesList:
    "&populate[portfolioItem][populate]=%2A&populate[seoInformation][populate]=%2A",
  portfolioPage: {
    seoInformation: "&populate=seoInformation",
    pageTitle: "",
  },
  topicsPage: {
    seoInformation: "&populate=seoInformation",
    pageTitle: "",
  },

  contactsPage: {
    seoInformation: "&populate[seoInformation][populate]=%2A",
    contactsPageFull: "&populate[seoInformation][populate]=%2A",
    pageTitle: "",
  },
  tags: "",
};

const JSONPaths: Record<string, string> = {
  mainPage: "data.0.attributes",
  staticTexts: "data.attributes",
  header: "data.attributes.header",
  footer: "data.attributes.footer",
  faqPage: "data.attributes",
  briefPage: "data.attributes",
  terms: "data.attributes.termsAndConditions",
  instagram: "data.attributes.gallery.data",
  notFound: "data.attributes",
  orderForm: "data.attributes",
  contactUs: "data.attributes",
  projectList: "data",
  articlesList: "data",
  portfolioPage: "data.0.attributes",
  topicsPage: "data.0.attributes",
  contactsPage: "data.0.attributes",
  pricesPage: "data.0.attributes",
  projectPage: "data.0.attributes",
  articlePage: "data.0.attributes",
  aboutPage: "data.attributes",
  tags: "data",
};

const modalProps = {
  modalState: false,
  type: "",
  content: null,
};

const fontMontserrat = {
  weight: ["200", "300", "400", "500", "700", "800"],
  subsets: ["cyrillic", "latin"],
  variable: "--font-montserrat",
  display: "swap",
};

const makePath = (
  collectionName: string,
  lang = "en",
  slug = "",
  sectionName?: string
) => {
  const filter = !!slug ? "&filters[slug][$eq]=" + slug : "";
 
  const section = !!sectionName
    ? exactPaths[collectionName][sectionName]
    : exactPaths[collectionName];

  return (
    baseUrl +
    collectionNames[collectionName] +
    localePath +
    lang +
    filter +
    section
  );
};

const apiCallOptions: RequestInit = {
  method: "GET",
  credentials: "same-origin",
  headers: {
    "Content-Type": "application/json",
  },
  // cache: "force-cache",
  // cache: "default", // Отключение кэша
  // next: { revalidate: 0 }, // Отключение ISR
  cache: "no-store",
};

const metricsId = {
  ru: {
    gtag: "G-RWMY8G540W",
    gtagAds: "AW-16447637131",
    yaMetric: "94470231",
    cookieBot: "0407e675-f348-4b5f-9e32-707243da5c07",
    gtagTagsId: "GTM-MC5CN8N8",
  },
  com: {
    gtag: "G-XCR4HVHWGK",
    gtagAds: "",
    yaMetric: "94482271",
    cookieBot: "d6275ed4-636e-4ff1-8e4a-3f65ea4128ec",
    gtagTagsId: "GTM-NQQ9QK48",
  },
};

const jsonLTDData = {
  siteNavigationElementRu: [
    {
      "@type": "SiteNavigationElement",
      name: "Главная",
      item: "https://blwall.ru/",
    },
    {
      "@type": "SiteNavigationElement",
      name: "Портфолио",
      item: "https://blwall.ru/portfolio",
    },
    {
      "@type": "SiteNavigationElement",
      name: "Контакты",
      item: "https://blwall.ru/contacts",
    },
    {
      "@type": "SiteNavigationElement",
      name: "Цены",
      item: "https://blwall.ru/prices",
    },
    {
      "@type": "SiteNavigationElement",
      name: "Статьи",
      item: "https://blwall.ru/topics",
    },
    {
      "@type": "SiteNavigationElement",
      name: "О нас",
      item: "https://blwall.ru/about",
    },
  ],
  siteNavigationElementCom: [
    {
      "@type": "SiteNavigationElement",
      name: "Main",
      item: "https://blwall.com/",
    },
    {
      "@type": "SiteNavigationElement",
      name: "Portfolio",
      item: "https://blwall.com/portfolio",
    },
    {
      "@type": "SiteNavigationElement",
      name: "Contacts",
      item: "https://blwall.com/contacts",
    },
    {
      "@type": "SiteNavigationElement",
      name: "Prices",
      item: "https://blwall.com/prices",
    },
    {
      "@type": "SiteNavigationElement",
      name: "About",
      item: "https://blwall.ru/about",
    },
    // {
    //   "@type": "SiteNavigationElement",
    //   "name": "Topics",
    //   "item": "https://blwall.com/topics",
    // }
  ],
};

const localeOptions = [
  {
    lang: "en",
    link: "https://blwall.com/",
  },
  {
    lang: "ru",
    link: "https://blwall.ru/",
  },
];

export {
  makePath,
  urlNames,
  JSONPaths,
  apiCallOptions,
  modalProps,
  fontMontserrat,
  metricsId,
  jsonLTDData,
  localeOptions,
};
