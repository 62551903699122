  "use client";

  import Image from "next/image";
  import { PageTitle } from "@/widgets/PageTitle/PageTitle";
  import "./Hero.css";
  import { IHeroClient } from "../types";
  import { Preloader } from "../../../shared/ui/Preloader/Preloader";
  import { useEffect, useRef, useState } from "react";
  import { BlurhashCanvas } from "react-blurhash";

  const HeroClient: React.FC<IHeroClient> = ({ content, image }) => {
    const imageRef = useRef<HTMLImageElement>(null);


const [isMobile, setIsMobile] = useState(false);

const mobImg =
    content.mobilePicture?.data?.attributes?.url
      ? `https://api.blwall.com${content.mobilePicture.data.attributes.url}`
      : image; 

  const img = isMobile ? mobImg : image;

  useEffect(() => {
    if (!window) {
      return;
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);


    useEffect(() => {
      const header = document.querySelector(".header");
      if (header) {
        header.classList.add("header_visible");
      }
    }, []);

    const handleAnimationEnd = () => {
      if (imageRef.current) {
        imageRef.current.classList.remove("hidden");
      }
    };

    return (
      <section className={"hero scroll-snap"}>
        <Preloader />
        <picture className={"hero__bg"}>
          <div className="blurhash-wrapper" onAnimationEnd={handleAnimationEnd}>
            <BlurhashCanvas
              hash={"LOHo2k%LR*D$~pt7R*IUM_WBfj-;"}
              width={1920}
              height={1080}
              punch={1}
              className="blurhash-placeholder"
            />
          </div>

          <Image
            className={`hero__bgImg hidden`}
            src={img}
            alt="Hero"
            width={1920}
            height={1080}
            ref={imageRef}
            quality={75}
            loading="eager"
            priority
          />
        </picture>
        <PageTitle
          title={content.title}
          description={content.description}
          subtitle={content.subtitle}
          autoslider={content.autoslider}
          position="fixed"
        />
        <div className={"hero__overlay"}></div>
      </section>
    );
  };

  export default HeroClient;
