"use client";

import { useEffect, useState } from "react";
import "./AwardsAndPress.css";
import { IAwards } from "../types";
import { AwardsAndPressMob } from "./AwardsAndPressMob";
import { AwardsAndPressDesktop } from "./AwardsAndPressDesktop";

const AwardsAndPressClient: React.FC<IAwards> = ({
  title,
  title2,
  awardsAndPress,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const awards = awardsAndPress.filter((item) => !item.moreText);
  const press = awardsAndPress.filter((item) => item.moreText === "press");
  const both = [...press, ...awards];

  useEffect(() => {
    if (!window) {
      return;
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {isMobile ? (
        <AwardsAndPressMob
          isMobile={isMobile}
          arr1={awards}
          arr2={press}
          title1={title}
          title2={title2}
        />
      ) : (
        <AwardsAndPressDesktop
          isMobile={isMobile}
          arr1={both}
          arr2={both}
          title1={title}
          title2={title2}
        />
      )}
    </>
  );
};

export default AwardsAndPressClient;
