"use client";

import { handleContactChange } from "@/widgets/Quiz/utils/utils";
import "./InputTextField.css";
import { IInputTextField } from "./interfaces";

export const InputTextField: React.FC<IInputTextField> = ({ fieldData }) => {
  return (
    <div className={`field ${fieldData.cssName}`}>
      <input
        className={`field__input`}
        type={fieldData.autoComplete}
        {...(fieldData.register ? fieldData.register(fieldData.cssName) : {})}
        name={fieldData.cssName}
        id={`form__${fieldData.cssName}`}
        minLength={fieldData.minLength}
        maxLength={fieldData.maxLength}
        placeholder="."
        required
        pattern={fieldData.pattern?.source}
        autoComplete={fieldData.autoComplete}
        onChange={
          fieldData.setContactInfo
            ? fieldData.onChange
            : undefined
        }
      />
      <label
        htmlFor={`form__${fieldData.cssName}`}
        className={`field__label ${fieldData.cssName}`}
      >
        {fieldData.label}
      </label>
    </div>
  );
};
