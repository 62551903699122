import { IQuizQuestion } from "../../types";
import './CheckboxQuestion.css';

interface CheckboxQuestionProps {
  question: IQuizQuestion;
  selectedAnswers: string[];
  onAnswerChange: (selectedAnswers: string[]) => void;
}

const CheckboxQuestion: React.FC<CheckboxQuestionProps> = ({
  question,
  selectedAnswers,
  onAnswerChange,
}) => {
  const handleCheckboxChange = (value: string) => {
    const updatedAnswers = selectedAnswers.includes(value)
      ? selectedAnswers.filter((answer) => answer !== value) // Удаляем значение, если уже выбрано
      : [...selectedAnswers, value]; // Добавляем новое значение
    onAnswerChange(updatedAnswers);
  };

  return (
    <div className="quiz__checkbox-group">
      {question.quizOptions.map((option) => (
        <label key={option.id} className="quiz__checkbox-option">
          <input
            type="checkbox"
            name={`question-${question.id}`}
            value={option.value}
            checked={selectedAnswers.includes(option.value)}
            onChange={() => handleCheckboxChange(option.value)}
            className="quiz__checkbox-input"
          />
          <span className="quiz__checkbox-label">{option.label}</span>
        </label>
      ))}
    </div>
  );
};

export default CheckboxQuestion;
