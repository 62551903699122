// fullpageEvents.ts
import { WheelEvent, KeyboardEvent, TouchEvent } from "react";

export interface FullpageEventHandlers {
  disableScrolling: boolean;
  direction: "vertical" | "horizontal";
  keyboard: boolean;
  touch: boolean;
  touchThreshold: number;
  allowContentScroll: boolean;

  // Методы листания:
  nextSlide: () => void;
  prevSlide: () => void;
}

/**
 * Обработка wheel-события
 */
export function handleWheelEvent(
  e:  React.WheelEvent<Element>,
  handlers: FullpageEventHandlers
) {
  if (handlers.disableScrolling) return;

  // Если нужно проверить "allowContentScroll" (вложенная прокрутка), 
  // здесь должна быть логика, определяющая, может ли внутренний контент прокрутиться.
  // Если может — return; иначе листаем.

  e.preventDefault();

  const delta = e.deltaY;
  if (delta > 0) {
    handlers.nextSlide();
  } else if (delta < 0) {
    handlers.prevSlide();
  }
}

/**
 * Обработка клавиатуры
 */
export function handleKeyDownEvent(
  e: React.KeyboardEvent<Element>,
  handlers: FullpageEventHandlers
) {
  if (!handlers.keyboard || handlers.disableScrolling) return;

  if (handlers.direction === "vertical") {
    if (e.key === "ArrowDown" || e.key === "PageDown") {
      e.preventDefault();
      handlers.nextSlide();
    } else if (e.key === "ArrowUp" || e.key === "PageUp") {
      e.preventDefault();
      handlers.prevSlide();
    }
  } else {
    if (e.key === "ArrowRight") {
      e.preventDefault();
      handlers.nextSlide();
    } else if (e.key === "ArrowLeft") {
      e.preventDefault();
      handlers.prevSlide();
    }
  }
}

/**
 * Логика тач-свайпа
 */
export function handleTouchStartEvent(
  e: React.TouchEvent<Element>,
  handlers: FullpageEventHandlers,
  setTouchStart: (value: number) => void
) {
  if (!handlers.touch || handlers.disableScrolling) return;

  if (handlers.direction === "vertical") {
    setTouchStart(e.touches[0].clientY);
  } else {
    setTouchStart(e.touches[0].clientX);
  }
}

export function handleTouchMoveEvent(
  e: React.TouchEvent<Element>,
  handlers: FullpageEventHandlers,
  touchStart: number | null,
  resetTouchStart: () => void
) {
  if (!handlers.touch || handlers.disableScrolling) return;
  if (touchStart === null) return;

  const currentPos =
    handlers.direction === "vertical"
      ? e.touches[0].clientY
      : e.touches[0].clientX;

  const diff = touchStart - currentPos;
  if (Math.abs(diff) > handlers.touchThreshold) {
    if (diff > 0) {
      handlers.nextSlide();
    } else {
      handlers.prevSlide();
    }
    resetTouchStart();
  }
}
