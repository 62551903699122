import { ICard } from "@/shared/interfaces/ICard";
import { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { TAwardsAndPressProps } from "../types";

export const AwardsAndPressDesktop: React.FC<TAwardsAndPressProps> = ({
  ...props
}) => {
  const { isMobile, arr1, title1, title2 } = props;

  const [visibleItems, setVisibleItems] = useState(0);
  const [showTitle2, setShowTitle2] = useState(false);

  useEffect(() => {
    if (!isMobile) {
      let totalItems = arr1.length;
      let delay = 350;
      const intervalId = window.setInterval(() => {
        setVisibleItems((prev) => {
          if (prev > totalItems / 2) {
            setShowTitle2(true);
          }
          if (prev < totalItems) {
            return prev + 1;
          } else {
            window.clearInterval(intervalId);
            return prev;
          }
        });
      }, delay);

      return () => window.clearInterval(intervalId);
    }
  }, [isMobile, arr1.length]);

  const placeholder =
    "data:image/bmp;base64,Qk32BAAAAAAAADYAAAAoAAAACAAAAAgAAAABABgAAAAAAMAAAAATCwAAEwsAAAAAAAAAAAAAKCgoMTExPj4+QUFBOjo6LCwsJSUlKioqHBwcKCgoODg4PT09NTU1JSUlHh4eJiYmCgoKHh4eMjIyODg4Li4uGxsbFBQUIiIiCwsLHR0dMDAwNDQ0KCgoEhISDw8PIiIiICAgKCgoMzMzMzMzJSUlDw8PFRUVKCgoMjIyNjY2OTk5NTU1JSUlFBQUHh4eMDAwPj4+Pz8/Pz8/Nzc3JycnGhoaJSUlNjY2QkJCQ0NDQUFBODg4KCgoHBwcKCgoOTk5";

  return (
    <section className="awards wrapper animate ">
      <div className="awards__title-wrapper">
        <h3 className="awards__title1 animation__text-rise">{title1}</h3>
        {showTitle2 && (
          <h3 className="awards__title2 animation__text-rise">
            {" " + title2}
          </h3>
        )}
      </div>
      <div className="awards__container">
        <div className="awards__wrapper">
          {arr1.map((item, index) => (
            <div
              key={item.title + index}
              className={`awards__item card ${
                visibleItems > index ? "visible" : "hidden"
              }`}
            >
              <Image
                className="awards__img"
                src={`https://api.blwall.com${item.image.data.attributes.url}`}
                alt={item.moreText ? "press img" : "award img"}
                width={300}
                height={300}
                title={item.title}
                loading="lazy"
                blurDataURL={placeholder}
                placeholder="blur"
              />
              <div className="awards__text">
                <h3 className="awards__itemTitle">{item.title}</h3>
                {item.subtitle && (
                  <Link href={item.link || ""} className="awards__itemSubtitle">
                    {item.subtitle}
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
