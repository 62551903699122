// fullpageDom.ts

/**
 * Пример простого "поиска" контейнера и слайдов
 * без React-хука. Просто ф-ция, которая возвращает объекты.
 */

export function findFullpageElements(
    containerSelector: string,
    slideSelector: string
  ) {
    const container = document.querySelector<HTMLElement>(containerSelector);
    if (!container) {
      console.warn(`[FullpageScript] Container not found: ${containerSelector}`);
    }
  
    const slides = container
      ? Array.from(container.querySelectorAll<HTMLElement>(slideSelector))
      : [];
  
    if (!slides.length) {
      console.warn(`[FullpageScript] No slides found using '${slideSelector}'`);
    }
  
    return {
      container,
      slides,
    };
  }
  