"use client";

import { useState } from "react";

import "./Quiz.css";
import { IQuizData } from "../types";
import QuizUi from "./QuizUi";

const QuizClient: React.FC<IQuizData> = (quizData) => {
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);

  const handleQuizComplete = (
    answers: { [key: number]: string | string[] },
    setAnswers: React.Dispatch<
      React.SetStateAction<{ [key: number]: string | string[] }>
    >,
    resetQuiz: () => void
  ) => {
    setAnswers(answers);
    setIsQuizCompleted(true);
  };

  return (
    <section className="quiz__wrapper wrapper animate">
      <QuizUi
        locale={quizData.locale}
        quizData={quizData}
        onComplete={handleQuizComplete}
      />
    </section>
  );
};

export default QuizClient;
