import styles from "./page.module.css";
import Image from "next/image";
import "./Avatars.css";
import { IAvatars } from "./interfaces";

export const Avatars: React.FC<IAvatars> = ({ members, display = "" }) => {
  return (
    <>
      {members &&
        members.map((member) => (
          <div className={`form__avatarContainer ${display}`} key={member.id}>
            <Image
              width={370}
              height={370}
              src={`https://api.blwall.com${member.avatar.data.attributes.url}`}
              alt={member.title + "image"}
              className={styles.form__avatar}
              title={member.title}
            />
            <div className={styles.form__textBlock}>
              <span className={styles.form__avatarTitle}>{member.title}</span>
              <span className={styles.form__avatarSubtitle}>
                {member.subtitle}
              </span>
            </div>
          </div>
        ))}
    </>
  );
};
