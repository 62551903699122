'use client'

import { useState, useEffect } from 'react';
import  './PageWidget.css';
import Link from 'next/link';
import Image from 'next/image';
import tgIcon from '@/shared/images/tg_logo.svg';

export default function PageWidget() {
  const [visible, setVisible] = useState(false);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    const showWidget = () => setVisible(true);
    const hideWidget = () => setVisible(false);

    const interval = setInterval(() => {
      if (!hovered) {
        showWidget();
        setTimeout(hideWidget, 5000); // Скрыть через 5 секунд
      }
    }, 15000); // Интервал появления каждые 15 секунд (10 сек скрыто + 5 сек видно)

    return () => clearInterval(interval);
  }, [hovered]);

  useEffect(() => {
    if (hovered) {
      setVisible(true); // Установить видимость при ховере
    }
  }, [hovered]);

  return (
    <div
      className={`pageWidget `}
      onMouseEnter={() => {
        setHovered(true);
        setVisible(true);
      }}
      onMouseLeave={() => setHovered(false)}
    >
      <Link href="https://t.me/blwall_brief_bot" target="_blank" rel="noopener noreferrer">
        <div className={`iconContainer ${visible ? 'visible' : 'hidden'}`}>
          <Image
            src={tgIcon}
            className="pageWidget__img"
            width={75}
            height={75}
            alt="page widget"
          />
        </div>
      </Link>
      {hovered && (
        <div className="popupContainer">
          <div className="popup">
            Наш бот в Телеграмме поможет с выбором!
          </div>
        </div>
      )}
    </div>
  );
}
