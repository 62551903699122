"use client";
import { useState, useEffect } from "react";
import "./AutotextSlider.css";

interface IAutotextSlider {
  autoslider: { text: string }[];

}
const AutotextSliderClient:React.FC<IAutotextSlider>  = ({autoslider}) => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % autoslider.length);
        setFade(false);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, [autoslider.length]);

  return (
    <div className="autotext-slider__container">
      <h4
        className={`autotext-slider__item ${
          fade
            ? "autotext-slider__item--fade-out"
            : "autotext-slider__item--fade-in"
        }`}
      >
        {autoslider[currentIndex].text}
      </h4>
    </div>
  );
};

export default AutotextSliderClient;

