"use client";

import { GalleryModal } from "./GalleryModal/GalleryModal";
import { DetailsModal } from "./DetailsModal/DetailsModal";
import { TermsModal } from "./TermsModal/TermsModal";
import { OrderModal } from "./OrderModal/OrderModal";
import { NavBarModal } from "./NavBarModal/NavBarModal";
import whiteCross from "@/shared/images/close_white.webp";
import blackCross from "@/shared/images/close_black.webp";
import Image from "next/image";
import "./SimpleModal.css";
import { useEffect } from "react";
import { usePathname } from "next/navigation";

const getModalType = (props: any) => {
  switch (props.type) {
    case "gallery":
      return <GalleryModal {...props} />;
    case "details":
      return <DetailsModal {...props} />;
    case "terms":
      return <TermsModal {...props} />;
    case "orderForm":
      return <OrderModal {...props} />;
    case "navbar":
      return <NavBarModal {...props} />;
  }
};

export const SimpleModal = (props: any) => {
  const path = usePathname();
  const pageDark = path === "/contacts" || path === "/";
  let isNarrow = { matches: true };

  if (typeof window !== "undefined") {
    isNarrow = window.matchMedia("screen and (max-width: 750px)");
  }
  const modalCross =
    pageDark ||
    isNarrow.matches ||
    props.modalProps.type === "terms" ||
    props.modalProps.type === "gallery"
      ? whiteCross
      : blackCross;

  useEffect(() => {
    if (props.modalProps.modalState) {
      document.body?.addEventListener("keydown", handleEsc);
    }
    if (typeof document !== "undefined" && props.modalProps.modalState) {
      document.body.style.overflow = "hidden";
    } else if (typeof document !== "undefined") {
      document.body.style.overflow = "unset";
    }
  }, [props.modalProps.modalState]);

  // на будующее - через интервал можно заставить плавно утекать форму
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsVisible(false);
  //   }, 1000);

  //   return () => clearTimeout(timer);
  // }, []);

  // if (props.modalProps.modalState)

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const targetElement = e.target as HTMLElement;

    if (
      props.modalProps.modalState &&
      targetElement.classList.contains("modal__container")
    ) {
      props.modalProps.handleModalState();
    }
  };

  const handleEsc = (e: KeyboardEvent) => {
    if (props.modalProps.modalState && e.key === "Escape") {
      props.modalProps.handleModalState();
    }
  };

  const modalType =
    props?.modalProps?.type === "navbar" ? "nav" : props?.modalProps?.type;

  return (
    <div
      className={`modal ${props.modalProps.modalState ? "active" : "hidden"}`}
      onMouseDown={handleClick}
      tabIndex={0}
    >
      <div className={`modal__container ${modalType}`}>
        {props.modalProps.modalState ? (
          <>
            <Image
              width={32}
              height={32}
              className={"modal__closeBttn"}
              alt="cross"
              src={modalCross}
              onClick={props.modalProps.handleModalState}
              data-id="close-modal"
            />
            {getModalType(props.modalProps)}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
