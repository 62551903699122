"use client";

import Link from "next/link";
import "./Navbar.css";
import { usePathname } from "next/navigation";
import { INavbarProps } from "./interfaces";
import { useEffect, useState } from "react";

export const Navbar: React.FC<INavbarProps> = ({ navbar }) => {
  const pathname = usePathname();
  const [clickedFaq, setClickedFaq] = useState(false);

  const scrollToFaq = () => {
    const faqElement = document.querySelector(".workflow");
    if (faqElement) {
      faqElement.scrollIntoView({ behavior: "smooth" });
    }

    // Закрываем модалку на мобильных устройствах
    if (window.matchMedia("(max-width: 700px)").matches) {
      setClickedFaq(true);
    }
  };

  useEffect(() => {
    if (clickedFaq) {
      const closeButton = document.querySelector(".modal__closeBttn");
      if (closeButton) {
        (closeButton as HTMLElement).click(); // Имитируем клик по кнопке закрытия
      }
      setClickedFaq(false); // Сбрасываем состояние после имитации клика
    }
  }, [clickedFaq]);

  return (
    <ul className="header__navbar">
      {navbar.map((navItem) => {
        // Отображение активного элемента
        const isCurrent = navItem.link === pathname || (pathname === "/" && navItem.link === "faq");

        if (navItem.link === "faq") {
          if (pathname === "/") {
            // Если на главной странице, используем <button> для скролла
            return (
              <button
                type="button"
                className={`header__navbarItem`}
                onClick={scrollToFaq}
                key={navItem.id}
              >
                {navItem.title}
              </button>
            );
          } else {
            // Если не на главной странице, используем <Link> для перехода
            return (
              <Link
                href="/faq"
                className={`header__navbarItem ${isCurrent ? "current" : ""}`}
                key={navItem.id}
              >
                {navItem.title}
              </Link>
            );
          }
        }

        // Ссылки для остальных пунктов меню
        return (
          <Link
            href={navItem.link}
            className={`header__navbarItem ${isCurrent ? "current" : ""}`}
            key={navItem.id}
          >
            {navItem.title}
          </Link>
        );
      })}
    </ul>
  );
};
