'use client';

import './DownloadFile.css';

interface DownloadLinkProps {
  label: string;
}

const DownloadLink: React.FC<DownloadLinkProps> = ({ label }) => {
const filePath='/project_example.pdf';

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = filePath;
    link.download = filePath.split('/').pop() || 'file.pdf'; // Имя файла по умолчанию
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button
      className="download-link"
      onClick={handleDownload}
      type="button"
      aria-label={`Download ${label}`}
    >
      {label}
    </button>
  );
};

export default DownloadLink;
