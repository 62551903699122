  "use client";

  import { useState } from "react";
  import { Navbar } from "@/shared/ui/Navbar/Navbar";
  import { OrderModal } from "../OrderModal/OrderModal";
  import "./NavBarModal.css";
  import { INavBarModal } from "./interfaces";

  export const NavBarModal = (props: INavBarModal) => {
    const [hideNav, setHideNav] = useState(false);
    const handleNideNav = () => {
      setHideNav(true);
    };

    let isNarrow = { matches: true };
    if (typeof window !== "undefined") {
      isNarrow = window.matchMedia("screen and (max-width: 750px)");
    }
    if (isNarrow.matches && !hideNav) {
      return (
        <>
          <Navbar navbar={props.content.navbar} />
          <hr className="modal__hr" />
          <button className="modal__orderButton" onClick={handleNideNav}>
            {props.content.contactUsButton}
          </button>
        </>
      );
    }

    return <OrderModal content={props.content.formData} />;
  };
