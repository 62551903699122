import './QuizProgress.css';

interface QuizProgressProps {
  currentStep: number;
  totalSteps: number;
}

const QuizProgress: React.FC<QuizProgressProps> = ({ currentStep, totalSteps }) => {
  const progressPercentage = (currentStep / totalSteps) * 100;

  return (
    <div className="quiz-progress">
      <div
        className="quiz-progress__bar"
        style={{ width: `${progressPercentage}%` }}
      />
    </div>
  );
};

export default QuizProgress;
